<template>
    <defaultSec :title="'属性'">
        <keep-alive>
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="属性名称">
                    <el-input v-model="formParam.propertyName" placeholder="请输入属性名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="字段">
                    <el-input v-model="formParam.propertyField" placeholder="请输入字段" clearable></el-input>
                </el-form-item>
                <el-form-item label="属性分类">
                    <el-select v-model="formParam.propertyClass"  filterable clearable  placeholder="请选择属性分类">
                        <el-option v-for="item in propertyClassList"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
                    <el-button type="success" @click="add">新建</el-button>
                </el-form-item>
            </el-form>
        </keep-alive>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="dataList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="标识" prop="propertyId" width="80"></el-table-column>
                <el-table-column label="名称" prop="propertyName" width="190"></el-table-column>
                <el-table-column label="字段" prop="propertyField"></el-table-column>
                <el-table-column label="类型" prop="propertyTypeName" width="150" ></el-table-column>
                <el-table-column label="数据类型" prop="properytDataType" width="120" ></el-table-column>
                <el-table-column label="排序" prop="sort" width="80"></el-table-column>
                <el-table-column label="分类" prop="propertyClassName" width="150" ></el-table-column>
                <el-table-column label="示例" prop="propertyDemo" width="120" ></el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                <el-table-column label="操作" fixed="right" width="140">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="warning " size="mini"
                                       @click="toDetail(scope.row)">详情
                            </el-button>
                            <el-button type="danger " size="mini"
                                       @click="deleteProerty(scope.row.propertyId)">删除
                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 15,
                    currentPage: 1,
                    propertyName:'',
                    propertyField:'',
                    propertyType:1,
                    propertyClass:''
                },
                dataList: [],
                propertyTypeList:[],
                propertyClassList:[],
                propertyList:[],
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initList()
            this.initPropertyTypeList()
            this.initPropertyClassList()
        },
        methods: {
            initList() {
                this.dataList=[]
                this.$apiGet('lechun-cms/scrmproperty/getPagePropertyList', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.dataList = res.list
                })
            },
            initPropertyTypeList(){
                this.$apiGet('lechun-cms/scrmproperty/getPropertyTypeList', {}).then(res => {
                    console.log(res)
                    this.propertyTypeList=res
                })
            },
            initPropertyClassList(){
                this.$apiGet('lechun-cms/scrmproperty/getPropertyClassList', {}).then(res => {
                    console.log(res)
                    this.propertyClassList=res
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            toDetail(row){
                this.$router.push({name: 'propertyDetail',params:{propertyId:row.propertyId}})
            },
            add(){
                this.$router.push({name: 'propertyDetail',params:{propertyId:0}})
            },
            deleteProerty(propertyId){
                this.$confirm('删除后不可撤销，确定删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmproperty/deleteProperty', {propertyId:propertyId}).then(res => {
                        console.log(res)

                    })
                }).catch(() => {

                });
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>